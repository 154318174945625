import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  TagField,
  ShowButton,
  Card,
  Form,
  Select,
  Button,
  Icons,
  useSelect,
} from "@pankod/refine-antd";
import { ICampaign, IContents, IHealthInsurer } from "interfaces";

export const CampaignList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    sorter,
    searchFormProps,
    tableQueryResult
} = useTable<ICampaign>({
    resource: "v1/campaign/list",
    initialCurrent: 1,
    initialPageSize: 20,
    hasPagination: true,
    onSearch: (values: any) => {
        return [
          {
            field: "campaign_id",
            operator: "eq",
            value: values.campaign_id,
          },
          {
            field: "health_insurer_id",
            operator: "eq",
            value: values.health_insurer_id,
          },
        ];
      },
  });

  const { selectProps } = useSelect<IHealthInsurer>({
    resource: "v1/health-insurer",
    optionLabel: "Description",
    optionValue: "HealthInsurerId",
    hasPagination: true,
  });

  return (
    <>
    <Card title="Busca">
        <Form {...searchFormProps} layout="inline">
          <Space>
            <Form.Item name="health_insurer_id" label="Filtrar Operadora:">
              <Select {...selectProps} options={[{ label: 'Todas', value: '' },...selectProps.options!]} style={{ width: "200px" }} />
            </Form.Item>
            <Button
              size="small"
              type="primary"
              icon={<Icons.SearchOutlined />}
              onClick={searchFormProps.form?.submit}
            >
              Buscar
            </Button>
          </Space>
        </Form>
      </Card>
    <List>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          dataIndex="health_insurer_name"
          key="health_insurer_name"
          title="Operadora"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("health_insurer_name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="message"
          key="message"
          title="Mensagem"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("message", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="campaign_id"
          key="campaign_id"
          title="Id da Campanha"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("campaign_id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="tag"
          key="tag"
          title="Tag"
          render={(value) => <TagField value={value} />}
          defaultSortOrder={getDefaultSortOrder("tag", sorter)}
          sorter
        />

        <Table.Column<IContents>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} onSuccess={() => tableQueryResult.refetch()} />
            </Space>
          )}
        />
      </Table>
    </List>
    </>
  );
};
