import {
    Form,
    Input,
    Select,
    useSelect,
  } from "@pankod/refine-antd";
  import { MyFormProps } from ".";
import { IHealthInsurer } from "interfaces";
  
  export const CampaignForm: React.FC<MyFormProps> = ({formProps}) => {
    const { selectProps } = useSelect<IHealthInsurer>({
      resource: "v1/health-insurer",
      optionLabel: "Description",
      optionValue: "HealthInsurerId",
      hasPagination: true,
    });

    const formStyle = {
      textArea: {
        width: "100%",
        height: "14rem"
      }
    }

    return (
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Id da Campanha"
          name="campaign_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Operadora"
          name="health_insurer_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...selectProps} />
        </Form.Item>

        <Form.Item
          label="Mensagem"
          name="message"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <textarea style={formStyle.textArea}/>
        </Form.Item>
        
        <Form.Item
          label="Tag"
          name="tag"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    );
  
  }